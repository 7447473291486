// 注意喚起メッセージのStyle
.literacy-caution {
  margin-top: 10px;
  padding: 1rem;
  border-radius: 4px;
  background-color: $ui-base-color;
}

// リンクの装飾を無くす
.literacy-caution a,
.literacy-caution a:link,
.literacy-caution a:visited,
.literacy-caution a:hover,
.literacy-caution a:active {
  color: $primary-text-color;
  text-decoration: none;
}
